import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/dayan.png" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/jose-gonzalez.png" // Tell webpack this JS file uses this image
import pal14 from "../images/palestrantes/jose-monteiro.png" // Tell webpack this JS file uses this image
import pal15 from "../images/palestrantes/david.png" // Tell webpack this JS file uses this image
import pal16 from "../images/palestrantes/ramon.png" // Tell webpack this JS file uses this image
import pal17 from "../images/palestrantes/felix.png" // Tell webpack this JS file uses this image
import pal18 from "../images/palestrantes/mikel.png" // Tell webpack this JS file uses this image

import Footer from "../components/footer"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/fmv.png" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/logo-agronomia.jpg" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Gestão ambiental<br></br></span> em explorações de bovinos <br></br>
            
            </div>
            <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                      2 e 3 de Novembro, 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      375€ +IVA
                      </div>
                    
                    </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal15} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Dr. David Fangueiro
                      </div>
                    
                    <div className="titulo inter-light">
                    Especialista em Gestão de Resíduos Orgânicos Agrícolas
                      </div>
                      </div>
                    
                  </div>


                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal16} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Eng. Ramon Gea
                      </div>
                    
                    <div className="titulo inter-light">
                    Especialista em gestão ambiental - SEGALÉS
                      </div>
                      </div>
                    
                  </div>

                
              
                </div>
                <div className="col-right inter-regular">
                <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal17} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Felix Freixer
                      </div>
                    
                    <div className="titulo inter-light">
                    Especialista em gestão ambiental - SEGALÉS
                      </div>
                      </div>
                    
                  </div>

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal18} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Mikel Zubicaray
                      </div>
                    
                    <div className="titulo inter-light">
                    Especialista em gestão ambiental - SEGALÉS
                      </div>
                      </div>
                    
                  </div>
                   
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/gestao-ambiental-bovinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/6gCFanUfG5UUk3Mx9" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Identificação e resolução de problemas associados à gestão de
efluentes;<br></br>
Conhecer as fontes e compreender os mecanismos de poluição
gerados pela produção animal;<br></br>
Implementar procedimentos que contribuam para a sustentabilidade
dos sistemas de produção;<br></br>
Conhecer e compreender os requisitos legais;<br></br>
Resolução de problemas ambientais associados a explorações
pecuárias: ar, água e solo;<br></br>
Elaboração de planos de gestão de efluentes pecuários.
                </div>
                <div className="logos">
                  
                <img src={logo6} alt="" />
              

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
   
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 02/11/2023
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 10:40</span> | <span class="mod">Módulo 1</span> - <span class="txt">

Chorume: Composição, problemas
e legislação
</span></p>
                                </div> 
                    
                          
                                <div class="modulo">
                                  <p><span className="date">10:40 - 11:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:40</span> | <span className="mod">Módulo 2</span> - <span class="txt">
                                  Valor agronómico do chorume: Gases, linhas
de tratamento e técnicas de transformação
</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">11:40 - 12:20</span> | <span className="mod">Módulo 3</span> - <span class="txt">
                                  Produção de biogás e digestão de chorume
</span></p>
                                </div>
                         
                                <div class="modulo">
                                     <p><span className="date">12:20 - 13:00</span> | <span className="mod">Almoço</span></p>
                                   </div>

                                   <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">
                                  Agitação e acidificação
</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:00</span> | <span className="mod">Módulo 5</span> - <span class="txt">
                                  Separação sólidos e líquidos (osmose e outras técnicas)
</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">15:00 - 16:00</span> | <span className="mod">Módulo 6</span> - <span class="txt">
                                  Valorização da fração líquido do chorume
</span></p>
                                </div>

                                <div class="modulo">
                                     <p><span className="date">16:00 - 17:20</span> | <span className="mod">Coffee-Break</span></p>
                                   </div>
                                   <div class="modulo">
                                  <p><span className="date">17:20 - 18:00</span> | <span className="mod">Módulo 7</span> - <span class="txt">
                                  Valorização da fração sólida do chorume
</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">18:00 - 18:30</span> | <span className="mod">Módulo 8</span> - <span class="txt">
                                  Venda de créditos de carbono (Dra. Inês Prata - Serbuvet)
</span></p>
                                </div>
                                
                              
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                              
                            + Dia 2 - 03/11/2023
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 10:15</span> | <span class="mod">Módulo 1</span> - <span class="txt">
                                Camas de sólidos para vacas: <br></br>características, riscos e benefícios
</span></p>
                                </div> 
                    
                          
                                <div class="modulo">
                                  <p><span className="date">10:15 - 10:45</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:45 - 11:15</span> | <span className="mod">Módulo 2</span> - <span class="txt">
                               Gestão de azoto
</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">11:15 - 12:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">
                                  Túneis de evaporação
</span></p>
                                </div>
                         
                                <div class="modulo">
                                     <p><span className="date">12:20 - 13:00</span> | <span className="mod">Almoço</span></p>
                                   </div>

                                   {/* <div class="modulo">
                                  <p><span className="date">14:30 - 17:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">
                                  Visita técnica a exploração: Barão e Barão
</span></p>
                                </div> */}

                      
                                
                              
                                </Accordion.Body>

                                </Accordion.Item>

                          

                                
                           
                      
                         
                          
                             


                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/gestao-ambiental-bovinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                <div className="form">
                <a href="https://forms.gle/6gCFanUfG5UUk3Mx9" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal15} alt="" />

              </div>
              <div className="nome">
               Dr. David Fangueiro
              </div>
              <div className="titulo">
              Especialista em Gestão de Resíduos Orgânicos Agrícolas
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Professor no ISA
</p>
         
             
                  </div>
              </div>
            </div>
        </div>
        </div>

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal16} alt="" />

              </div>
              <div className="nome">
               Eng. Ramon Gea
              </div>
              <div className="titulo">
              Especialista em gestão ambiental -
SEGALÉS
                </div>
          </div>
          <div className="body">
          
            </div>
        </div>
        </div>

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal17} alt="" />

              </div>
              <div className="nome">
               Eng. Felix Freixer
              </div>
              <div className="titulo">
              Especialista em gestão ambiental -
SEGALÉS
                </div>
          </div>
          <div className="body">
          
            </div>
        </div>
        </div>

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal18} alt="" />

              </div>
              <div className="nome">
               Eng. Mikel Zubicaray
              </div>
              <div className="titulo">
              Especialista em gestão ambiental -
SEGALÉS
                </div>
          </div>
          <div className="body">
          
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
